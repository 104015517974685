@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.warningBadge {
  font-size: 30%;
  margin-left: 10px;
  vertical-align: top;
  margin-top: 10px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.Row.closed {
  display: none;
}

.closeButton {
  float: left;
  margin-right: 15px;
}

.pointable {
  cursor: pointer;
}

.ScoreEntry {
  width: 4em;
  text-align: right;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.FileDropTarget {
  flex-grow: 1;
  margin: 0 10px 0 10px;
  text-align: center;
}

.FileDropTargetBox {
  width: 100%;
  height: 400px;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 2px dotted black;
  display: table;
  text-align: center;
}

.FileDropTargetBox[hover="true"] {
  border-color: orange;
}

.FileDropTarget .centeredTextHolder {
  display: table-cell;
  vertical-align: middle;
  line-height: normal;
}

.FileDropTarget .fileUploadButton {
  width: 100%;
  height: 40px;
  border: 1px solid black;
  text-align: center;
}

.FileDropTarget .fileUploadButton div {
  width: 100%;
  height: 100%;
  display: table;
}

.FileDropTarget .fileUploadButton:hover {
  background-color: lightblue;
  cursor: pointer;
}

.UploadTargets {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.Dropdown {
  margin-right: 10px;
}

.footer {
  background-color: #f5f5f5;
}

